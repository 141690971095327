<template>
	<div class="d-flex flex-column max-height-100">
		<!-- Cabecera -->
		<!--<map-panel-header :height="headerHeight" />-->

		<div class="d-flex flex-grow-1 fill-height" v-if="slaDevices" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
			<v-card
				elevation="2"
				style="width: max(280px, 15vw) !important; min-width: 280px !important; z-index: 1"
				class="dashboard-panel-info dashboard-panel-details"
			>
				<v-card-title>{{ $t('panels.dashboard.title-' + byArea) }}</v-card-title>
				<!-- <div class="settings-title fw-bold">{{ $t('panels.dashboard.title-' + byArea) }}</div> -->
				<v-tabs fixed-tabs height="30" v-model="tab" color="blue" background-color="rgba(0, 0, 0, 0)" show-arrows>
					<v-tab ref="sla-area" href="#sla-area">{{ $t('panels.dashboard.sla-area') }}</v-tab>
					<v-tab ref="sla-datasource" href="#sla-datasource">{{ $t('panels.dashboard.sla-datasource') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item value="sla-area">
						<transition name="scroll-x-transition" mode="out-in" :duration="100">
							<data-quality-kpi-panel :type="byArea"></data-quality-kpi-panel>
						</transition>
					</v-tab-item>
					<v-tab-item value="sla-datasource">
						<transition name="scroll-x-transition" mode="out-in" :duration="100">
							<data-quality-kpi-panel :type="byDatasource"></data-quality-kpi-panel>
						</transition>
					</v-tab-item>
				</v-tabs-items>
			</v-card>

			<div id="dashboard-panel-content" class="d-flex overflow-auto" style="width: calc(100% - max(280px, 15vw)) !important">
				<dashboard-panel-content :type="byGlobal" />
				<!-- <v-tabs-items v-model="tab" class="width-100">
					<v-tab-item value="sla-area" class="overflow-y-scroll"><dashboard-panel-content :type="byArea" /></v-tab-item>
					<v-tab-item value="sla-datasource" class="overflow-y-scroll"><dashboard-panel-content :type="byDatasource" /></v-tab-item>
				</v-tabs-items> -->
			</div>

			<!-- -->
		</div>
		<div v-else class="d-flex justify-center align-center" style="height: 100vh">
			<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
		</div>
		<!-- <configurations-panel /> -->
	</div>
</template>

<script>
import DataQualityKpiPanel from '@/components/dashboard/kpis/DataQualityKpiPanel';
import DashboardPanelContent from '@/components/dashboard/DashboardPanelContent';

import { search, isSuperAdmin } from '@/api/common';
import ReloadStoreMixin from '@/mixins/ReloadStoreMixin';

export default {
	name: 'DashboardPanel',
	components: {
		DataQualityKpiPanel,
		DashboardPanelContent
	},
	mixins: [ReloadStoreMixin],
	data() {
		return {
			isSuperAdmin: false,
			primaryColor: this.$store.state.global.primaryColor,
			byArea: 'area',
			byDatasource: 'datasource',
			byGlobal: 'global'
		};
	},
	computed: {
		headerHeight() {
			return this.$store.state.global.appHeaderMapHeightInPx;
		},
		slaDevices: {
			get() {
				return this.$store.getters.getSlaDevices;
			},
			set(value) {
				this.$store.commit('setSlaDevices', value);
			}
		},
		tab: {
			get() {
				return this.$store.getters.getDashboardTab;
			},
			set(value) {
				this.$store.commit('setDashboardTab', value);
			}
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		if (!this.userProperties) this.reloadFullStore().then(() => this.storeSlaData());
		else this.storeSlaData();
	},
	mounted() {
		const puiContainerHeader = document.getElementsByClassName('pui-containerHeader')[0];
		puiContainerHeader.style.display = 'none';
	},
	beforeDestroy() {
		console.log('info: beforedestroy dashboardpanel');

		if (document.getElementsByClassName('pui-containerHeader')[0]) {
			document.getElementsByClassName('pui-containerHeader')[0].style.display = '';
		}
	},
	methods: {
		async storeSlaData() {
			let filterUser = {};
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				};
			}
			let body = {
				model: 'pmslacalculations',
				rows: 1000,
				filter: filterUser
			};

			let { data } = await search(body);
			console.log('sla data:', data.data);
			this.slaDevices = data.data;
		}
	}
};
</script>

<style lang="postcss">
@import '../../styles/eiffel-variables.pcss';

.dashboard-panel-info {
	width: 100%;
	height: 100%;

	& .settings-title {
		font-size: 1.3rem !important;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: #000;
		margin-bottom: 2px;
	}
}

.dashboard-panel-details {
	width: 100%;
	background-color: var(--fondo-1) !important;
	box-shadow: 2px 0 10px 0 rgba(49, 59, 111, 0.31);
	border-right: 1px #ccc solid !important;
	border-radius: 0px !important;
}

.dashboard-panel-kpis {
	width: 100%;
	background-color: var(--fondo-1) !important;
	border-radius: 0px !important;
}
</style>
