<template>
	<v-list-group no-action :ripple="false" append-icon="" @click="toggleRotated" :value="true">
		<template #prependIcon>
			<v-icon size="14" :class="{ rotated: rotated }" class="color-high">
				far
				{{ rotated ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</template>
		<template #activator>
			<div class="d-flex flex-wrap width-100 ml-n8">
				<v-list-item-title class="color-high fw-bold fs-regular d-flex justify-space-between"
					><span>{{ item[nameField] }}</span>
					<div>
						<span v-if="Object.hasOwn(totalsGroup, item[idField])">{{ totalsGroup[item[idField]].percent.toFixed(2) }}%</span>
						<div v-else>
							<v-tooltip right max-width="350" transition="scroll-x-transition" color="00000099">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" size="14" class="fw-500 pr-1">fak fa-solid-anomalies</v-icon>
								</template>
								<span class="fw-500 fs-regular text-center">{{ $t('common.noData') }}</span>
							</v-tooltip>
							<!-- <span class="color-high"> - %</span> -->
						</div>
					</div>
				</v-list-item-title>
				<v-list-item-subtitle>
					<kpi-bar v-if="sectionsGroup[item[idField]]" :sections="sectionsGroup[item[idField]]" barHeight="8px"></kpi-bar>
				</v-list-item-subtitle>
			</div>
		</template>
		<v-list-item v-for="(device, i) in item.group" :key="i" class="sub-kpi fw-500">
			<v-list-item-content>
				<v-list-item-title
					class="d-flex justify-space-between"
					@click="filterDeviceChart(device)"
					:style="{ cursor: !isByArea ? 'pointer' : 'default' }"
				>
					<v-tooltip
						v-if="teoricSlaDevices[device.pmdatasourceinstanceid]"
						right
						max-width="350"
						transition="scroll-x-transition"
						:color="primaryColor"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="cursor: pointer">
								<span class="color-high">{{ device.code }}</span>
								<v-icon size="12" class="fw-500 pl-2">fak fa-line-info-2</v-icon>
							</div>
						</template>
						<span class="fw-500 fs-regular text-center"
							>{{ $t('panels.dashboard.device-sla') }}: {{ teoricSlaDevices[device.pmdatasourceinstanceid] }}%</span
						>
					</v-tooltip>
					<v-tooltip v-else right max-width="350" transition="scroll-x-transition" :color="primaryColor">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="cursor: pointer">
								<span class="color-high">{{ device.code }}</span>
								<i class="fas fa-do-not-enter pl-2" style="color: var(--gris-300)"></i>
							</div>
						</template>
						<span class="fw-500 fs-regular text-center">{{ $t('panels.dashboard.device-sla') }}: {{ $t('common.notDefined') }}</span>
					</v-tooltip>
					<!-- <span v-else class="color-high">{{ device.code }}</span> -->
					<div>
						<v-tooltip
							v-if="
								totalsDevices[device.pmdatasourceinstanceid] &&
								teoricSlaDevices[device.pmdatasourceinstanceid] > totalsDevices[device.pmdatasourceinstanceid]
							"
							right
							nudge-right="50"
							max-width="400"
							transition="scroll-x-transition"
							color="00000099"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" size="14" class="fw-500 pr-1" style="color: var(--amarillo-100) !important">
									fak fa-solid-alert
								</v-icon>
							</template>
							<span class="fw-500 fs-regular text-center">{{ $t('panels.dashboard.slaWarning') }}</span>
						</v-tooltip>
						<span v-if="totalsDevices[device.pmdatasourceinstanceid]" class="color-high">
							{{ totalsDevices[device.pmdatasourceinstanceid].toFixed(2) }}%
						</span>
						<div v-else>
							<v-tooltip right max-width="350" transition="scroll-x-transition" color="00000099">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" size="13" class="fw-500 pr-1"> fak fa-solid-anomalies </v-icon>
								</template>
								<span class="fw-500 fs-regular text-center">{{ $t('common.noData') }}</span>
							</v-tooltip>
							<!-- <span class="color-high"> - %</span> -->
						</div>
					</div>
				</v-list-item-title>
				<v-list-item-subtitle
					><kpi-bar
						v-if="sectionsDevices[device.pmdatasourceinstanceid]"
						:sections="sectionsDevices[device.pmdatasourceinstanceid]"
						barHeight="6px"
					></kpi-bar
				></v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list-group>
</template>

<script>
import KpiBar from './KpiBar.vue';
export default {
	components: {
		KpiBar
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		sectionsGroup: {
			type: Object,
			required: true
		},
		totalsGroup: {
			type: Object,
			required: true
		},
		idField: {
			type: String,
			required: true
		},
		nameField: {
			type: String,
			required: true
		},
		sectionsDevices: {
			type: Object,
			required: true
		},
		totalsDevices: {
			type: Object,
			required: true
		},
		teoricSlaDevices: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			rotated: true,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		isByArea() {
			return this.idField == 'pmareaid';
		},
		slaChartFilter() {
			return this.$store.getters.getSlaChartFilter;
		}
	},
	watch: {},
	mounted() {
		console.log('mounted kpi panel group', this.sectionsDevices, this.sectionsGroup);
	},
	methods: {
		toggleRotated() {
			this.rotated = !this.rotated;
		},
		filterDeviceChart(device) {
			console.log('filter device chart', device, this.isByArea, this.totalsDevices[device.pmdatasourceinstanceid]);
			if (!this.isByArea && this.totalsDevices[device.pmdatasourceinstanceid]) {
				if (this.slaChartFilter && this.slaChartFilter.pmdatasourceinstanceid == device.pmdatasourceinstanceid) {
					device = null;
				}
				this.$store.commit('setSlaChartFilter', device);
			}
		}
	}
};
</script>

<style lang="postcss">
.fak.fa-solid-anomalies {
	color: var(--gris-300) !important;
}
</style>
